import React, { Component }  from 'react'
import '../css/events-list.scss'
import { motion } from "framer-motion";
import Header from "./Header"
import  { events } from '../Api/ApiEvents'
import * as constants from '../Constants/Constants'
import { Link } from 'react-router-dom';
import { withTranslation } from 'react-i18next';



class EventsList extends Component { 
    constructor(props){
        super(props);
        this.state = {
            events : events,
            light : this.props.light ? "true" : "false"
        }
    }
    

    displayAll = (events) => {
        let { t } = this.props;
        return(
            <div className="complete">
                <Header backButton="true"/>
                <div className="content">
                    <h2>{t('Nos événements')}</h2>
                    <div className="events-list">
                        {
                        events.map((ev, i)=> (
                            <div key={i} className="item">
                                <Link to={{pathname : '/event/'+ev.slug}}>
                                <div className="image">
                                    <img src={ev.image} alt={ev.name} className="event-picture"/>
                                    <div className="link-text">
                                        <span>{ev.shortDescription}</span>
                                        <span className="link">{t('Voir plus')} <img src={`${constants.PATH_TO_IMAGES+"arrow-next.png"}`} alt="Icon see more"/></span>
                                    </div>
                                </div>
                                <div className="title">
                                    {ev.name}
                                </div>
                            </Link>
                        </div>
                        
                        ))
                        } 
                    </div>
                </div>
            </div>
        )
    }

    displayLight = (events) => {
        let { t } = this.props;
        let eventsLight = events.slice(0, 3);
        return(
            <div className="light">
                <Link to={{pathname : '/events'}} className="mobile-only">
                    <div className="see-all">Voir tout <img src={`${constants.PATH_TO_IMAGES+"arrow-next.png"}`} alt="Icon see more"/></div>
                </Link>
                <div className="light-list">
                    {
                    eventsLight.map((ev, i)=> (
                        <div key={i} className="item">
                            <Link to={{pathname : '/event/'+ev.slug}}>
                            <div className="image">
                                <img src={ev.image} alt={ev.name} className="event-picture"/>
                                <div className="link-text">
                                    <span>{ev.shortDescription}</span>
                                    <span className="link">Voir plus <img src={`${constants.PATH_TO_IMAGES+"arrow-next.png"}`} alt="Icon see more"/></span>
                                </div>
                            </div>
                            <div className="title">
                                {ev.name}
                            </div>
                            </Link>
                        </div>
                    ))
                    } 
                </div>
                <Link to={{pathname : '/events'}} className="desktop-only">
                    <div className="see-all">{t('Voir tout')} <img src={`${constants.PATH_TO_IMAGES+"arrow-next.png"}`} alt="Icon see more"/></div>
                </Link>
            </div>
        )
    }

    render() {
        let { t } = this.props;
        return(
            <div>
                <div id="events-list">
                    {this.state.light === "false" && 
                    <div>
                        <motion.div
                        style={pageStyle}
                        initial="initial"
                        animate="in"
                        exit="out"
                        variants={pageVariants}
                        transition={pageTransition}
                        >
                        {this.displayAll(this.state.events)}
                        </motion.div>
                    </div>
                    }
                    {this.state.light === "true" && 
                    <div>
                        <h2>{t('Nos événements')}</h2>
                        {this.displayLight(this.state.events)}
                        </div>
                    }
                </div>
            </div>
        );
    }
}
const pageVariants = {
    initial: {
        opacity: 0,
        position : "relative"

    },
    in: {
        opacity: 1,
        position : "relative"

    },
    out: {
        opacity: 0,
        position : "absolute"
    }
};

const pageTransition = {
    type: "crossfade",
    ease: "anticipate",
    duration: 1
};

const pageStyle = {
    //position: "absolute",
    width : "100%"
};
export default withTranslation()(EventsList);