import React, { Component }  from 'react'
import Header from './Header'
import GamesList from './GamesList'
import { Link } from 'react-router-dom';
import { motion } from "framer-motion";
import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
} from 'react-accessible-accordion';
import i18n from "i18next";
import * as constants from '../Constants/Constants'
import { withTranslation } from 'react-i18next';
import '../css/game.scss'
import {Helmet} from "react-helmet";


class TexasHoldEm extends Component {

    componentDidMount() {
        setTimeout(function(){
            window.scrollTo({
                top: 0,
                left: 0,
                behavior: 'smooth'
            });
        }, 100);
        if(document.getElementById("punto-footer") !== null) {
            let pfooter = document.getElementById("punto-footer")
            pfooter.style.display = "block";
        }
        document.body.classList.remove("no-footer");
    }

    render() {
        let { t } = this.props;
        return(
            <div>
                <Helmet htmlAttributes={{ lang : i18n.language }}>
                    <title>{t('Texas Hold\'em- Punto Club Paris')}</title>
                    <meta name="description" content={t('Jouez aux tables de Texas Hold\'em du Punto Club')} />
                </Helmet>
                <motion.div
                    style={pageStyle}
                    initial="initial"
                    animate="in"
                    exit="out"
                    variants={pageVariants}
                    transition={pageTransition}
                >
                    <div id="poker-21">
                        <Header backButton="true"/>
                        <div className="contact">
                            <Link to={{ pathname: '/coordinates',  state: { from: this.props.location.pathname } }} >
                                <img src={`${constants.PATH_TO_IMAGES+"gold-geoloc.png"}`} alt="Icon" />
                                <span>{t('S\'y rendre')}</span><span>/</span><span>{t('Contact')}</span>
                            </Link>
                        </div>
                        <div className="top">
                            <h1>{t('Texas Hold\'em Poker Cash Game')}</h1>
                            <p>{t('texas description')}</p>
                        </div>
                        <div className="content">

                            <img className="illustration-game" src={`${constants.PATH_TO_IMAGES+"holdem.jpg"}`} alt="Texas Hold em illustration" />

                            <div className="games-list block">
                                <h3>{t('D\'autres jeux')}</h3>
                                <GamesList hide="texas-hold-em"/>
                            </div>
                        </div>
                    </div>
                </motion.div>
            </div>
        );
    }
}
const pageVariants = {
    initial: {
        opacity: 0,
        position : "relative"

    },
    in: {
        opacity: 1,
        position : "relative"

    },
    out: {
        opacity: 0,
        position : "absolute"
    }
};

const pageTransition = {
    type: "crossfade",
    ease: "anticipate",
    duration: 1
};

const pageStyle = {
    //position: "absolute",
    width : "100%"
};
export default withTranslation()(TexasHoldEm);
