import React, { Component }  from 'react'
import '../css/contact.scss'
import { motion } from "framer-motion";
import { Link } from 'react-router-dom';
import { withTranslation } from 'react-i18next';

import * as constants from '../Constants/Constants'
import MetaTags from "react-meta-tags";
import {Helmet} from "react-helmet";
import i18n from "i18next";


class Contact extends Component { 
    constructor(props){
        super(props);
        this.state = {
            from : this.props.location.state ? this.props.location.state.from : '/'
        }
    }

    componentDidMount() {
        setTimeout(function(){
            window.scrollTo({
                top: 0,
                left: 0,
                behavior: 'smooth'
            });
        }, 100);
        if(document.getElementById("punto-footer") !== null) {
            let pfooter = document.getElementById("punto-footer")
            pfooter.style.display = "none";
        }
        document.body.classList.add("no-footer");
      }
      
    render() {
        let { t } = this.props;
        return(
            <motion.div
                style={pageStyle}
                initial="initial"
                animate="in"
                exit="out"
                variants={pageVariants}
                transition={pageTransition}
                >
                <Helmet htmlAttributes={{ lang : i18n.language }}>
                    <title>{t('Vous avez une question ? Contactez-nous – Punto Club')}</title>
                    <meta name="description" content={t('Nous sommes joignables par téléphone, mail mais aussi via les réseaux sociaux. Besoin d’aide ? Nous sommes là pour vous !')} />
                </Helmet>
                    <div id="contact">
                        <div id="close-contact">
                            <Link to={{ pathname: this.state.from }} >
                                {t('Fermer')}
                            </Link>
                        </div>
                        <div id="content">
                        <div className="title">
                            {t('Se rendre au Punto Club')}
                        </div>
                        <div className="infos">
                            <div className="address mobile-only">
                                <div className="icon">
                                    <img src={`${constants.PATH_TO_IMAGES+"white-geoloc.png"}`} alt="Icon"/>
                                </div>
                                <a href="https://goo.gl/maps/Um7GQZrS91ZG5MkKA" target="_blank" rel="noopener noreferrer">
                                <div className="street">
                                    <div>11 rue de Berri</div>
                                    <div>75008 Paris</div>  
                                </div>
                                </a>
                                <div className="hours">
                                    {t('Ouvert 7j/7')}<br/>
                                    {t('De 21h à 05h')}
                                </div>
                            </div>
                            <div className="metro">
                                <div className="icon">
                                    M
                                </div>
                                <div className="type">
                                    {t('Métro')}
                                </div>
                                <div className="stations">
                                    {t('Ligne 1 - arrêt Georges V')}<br/>
                                    {t('Ligne 9 - arrêt Saint-Philippe-du-Roule')}
                                </div>
                            </div>
                            <div className="address desktop-only">
                                <div className="icon">
                                    <img src={`${constants.PATH_TO_IMAGES+"geoloc.png"}`} alt="Icon"/>
                                </div>
                                <a href="https://goo.gl/maps/Um7GQZrS91ZG5MkKA" target="_blank" rel="noopener noreferrer">
                                <div className="street">
                                    <div>11 rue de Berri</div>
                                    <div>75008 Paris</div>  
                                </div>
                                </a>
                                <div className="hours">
                                    {t('Ouvert 7j/7')}<br/>
                                    {t('De 21h à 05h')}
                                </div>
                            </div>
                            <div className="parking"> 
                            <div className="icon">
                                    P
                                </div>
                                <div className="type">
                                    {t('Parking')}
                                </div>
                                <div className="places">
                                    <div className="parking-address"> 
                                        <span className="bold">Berri Ch. &Eacute;lysées</span>
                                        <span>6 rue de Berri</span>
                                    </div>
                                    <div className="parking-address">
                                        <span className="bold">Interparking</span>
                                        <span>5 rue de Berri</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="message">
                            <div className="phone">
                                <a href="tel:0153838448">
                                    <img src={`${constants.PATH_TO_IMAGES+"phone.png"}`} alt="Icon"/>
                                    <span>{t('Nous contacter')}</span>
                                    <span>{t('01 53 83 84 48')}</span>
                                </a>
                                <span>{t('Ou')}</span>
                            </div>
                            <div className="mail">
                                <a href="mailto:informations@partouche.com">{t('envoyer un message')}</a>
                            </div>
                        </div>
                        </div>
                    </div>
                </motion.div>
        );
    }
}
const pageVariants = {
    initial: {
        opacity: 0,
        position : "relative"

    },
    in: {
        opacity: 1,
        position : "relative"

    },
    out: {
        opacity: 0,
        position : "absolute"
    }
};

const pageTransition = {
    type: "crossfade",
    ease: "anticipate",
    duration: 1
};

const pageStyle = {
    //position: "absolute",
    width : "100%",
    zIndex : 3
  };
export default withTranslation()(Contact);
