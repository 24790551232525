import React, { Component }  from 'react'
import Header from './Header'
import TutoPunto from './TutoPunto'
import GamesList from './GamesList'
import { Link } from 'react-router-dom';
import { motion } from "framer-motion";
import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
} from 'react-accessible-accordion';
import i18n from "i18next";
import * as constants from '../Constants/Constants'
import { withTranslation } from 'react-i18next';
import '../css/game.scss';
import {Helmet} from "react-helmet";


class Punto extends Component { 
    constructor(props){
        super(props);
        this.state = {
            minus : '&#8212;',
            plus : '+',
            visiblePuntoGame : false,
        }
    }
    componentDidMount() {
        setTimeout(function(){
            window.scrollTo({
                top: 0,
                left: 0,
                behavior: 'smooth'
            });
        }, 100);

        if(document.getElementById("punto-footer") !== null) {
            let pfooter = document.getElementById("punto-footer")
            pfooter.style.display = "block";
        }
        document.body.classList.remove("no-footer");
      }

    showPuntoGame = () => {
        this.setState({
            visiblePuntoGame : true
        })
    }

    hidePuntoGame = () => {
        this.setState({
            visiblePuntoGame : false
        })
    }

    render() {
        let { t } = this.props;
        return(
        <div>
            <Helmet htmlAttributes={{ lang : i18n.language }}>
                <title>{t('Découvrez le Punto Banco - Punto Club Paris')}</title>
                <meta name="description" content={t('En quelques clics, jouez gratuitement en ligne au Punto Banco. 2 tables de jeux y sont consacrées au Punto Club.')} />
            </Helmet>
        <motion.div
            style={pageStyle}
            initial="initial"
            animate="in"
            exit="out"
            variants={pageVariants}
            transition={pageTransition}
            >
            <div id="punto">
                <Header backButton="true"/>
                <div className="contact">
                    <Link to={{ pathname: '/coordinates',  state: { from: this.props.location.pathname } }} >
                        <img src={`${constants.PATH_TO_IMAGES+"gold-geoloc.png"}`} alt="Icon" />
                        <span>{t('S\'y rendre')}</span><span>/</span><span>{t('Contact')}</span>
                    </Link>
                </div>
                <div className="top">
                <h1>{t('Le punto banco')}</h1>
                    <h3>{t('Le Punto Banco, c\'est quoi ?')}</h3>
                    <p>{t('Tirant ses origines du Baccara, le Punto Banco est un jeu d\'une grande simplicité qui se joue avec 6 paquets de 52 cartes.')}</p>
                </div>
                <div className="content">
                    <div className="block icons">
                        <div id="tables">
                            <img src={`${constants.PATH_TO_IMAGES+"icon-tables.svg"}`} alt="Icon tables" />
                            <span>{t('3 tables de jeu')}</span>
                        </div>
                        <div id="open">
                            <img src={`${constants.PATH_TO_IMAGES+"icon-hours.svg"}`} alt="Icon hours" />
                            <span>{t('Ouvert 7j/7')}</span>
                        </div>
                    </div>
                </div>
                    <div className="block tuto">
                        <div className="mask"></div>
                        <div className="catchphrase">
                            <p>{t('Apprenez les bases du punto banco en moins de cinq minutes')}</p>
                            <div className="link">
                                <span className="open-game" onClick={this.showPuntoGame}>{t('Jouez gratuitement')}</span>
                                <img src="/punto/build/images/arrow-next.png" alt="Icon see more"/>
                            </div>
                        </div>
                        <TutoPunto
                            visible={this.state.visiblePuntoGame}
                            hide={this.hidePuntoGame}
                        />
                    </div>
                <div className="content">
                    <h3>{t('Les règles')}</h3>
                    <div className="accordion">              
                    <Accordion allowMultipleExpanded preExpanded={['a']}>
                        <AccordionItem uuid="a">
                            <AccordionItemHeading>
                                <AccordionItemButton>
                                    <h3>{t('Objectif')}</h3>
                                </AccordionItemButton>
                            </AccordionItemHeading>
                            <AccordionItemPanel>
                                <p>{t('L’objectif du punto banco est de prédire quelle sera la main gagnante')} : {t('Punto, banco ou égalité. Pour gagner, il suffit de miser sur la main qui aura obtenu un total de points se rapprochant le plus de 9 avec un maximum de 3 cartes.')}</p>
                                <p>{t('Il est également possible de miser sur "Egalité" et ainsi parier sur un score identique entre Punto et Banco')}</p>
                            </AccordionItemPanel>
                        </AccordionItem>
                        <AccordionItem  uuid="b">
                            <AccordionItemHeading>
                                <AccordionItemButton>
                                    <h3>{t('Valeurs de cartes')}</h3>
                                </AccordionItemButton>
                            </AccordionItemHeading>
                            <AccordionItemPanel>
                            <p>{t('Les cartes ont leur valeur nominale. L’as vaut un, les valets, dames, rois et 10 valent 0. Si le score des cartes dépasse 9, il n’est pas tenu compte du chiffre des dizaines. Seul est pris en considération le chiffre des unités.')} </p>
                            <a className="photo" href={`${constants.PATH_TO_IMAGES + i18n.language +"/punto/values.svg"}`}  >
                            <p className="mobile-only">{t('Cliquez pour agrandir')}</p>
                            <img src={`${constants.PATH_TO_IMAGES + i18n.language + "/punto/values.svg"}`} alt="Values"/>
                            </a>
                            <p>{t('Quand l\'addition des cartes dépasse 10 points, on ne compte plus les dizaines. Exemple')}: 7 + 7 = <span className="grey">(1)</span><span className="gold">4 {t('points')}.</span></p>
                            <p>{t('En fonction des règles de tirage définies, le croupier peut tirer jusqu\'à 3 cartes pour une main.')}</p>
                            </AccordionItemPanel>
                        </AccordionItem>
                        <AccordionItem  uuid="c">
                            <AccordionItemHeading>
                                <AccordionItemButton>
                                    <h3>{t('Déroulement du jeu')}</h3>
                                </AccordionItemButton>
                            </AccordionItemHeading>
                            <AccordionItemPanel>
                            <p>{t('Les joueurs misent avant la distribution des cartes. Ils peuvent miser sur Punto, sur Banco ou sur Égalité.')}<br/>
                                {t('Ils ont également la possibilité de miser simultanément sur Punto et Égalité ou Banco et Égalité.')}<br/>
                                {t('Les mises sont terminées lorsque le croupier déclare que “Rien ne va plus”. Il distribue alors faces cachées 2 cartes à Punto et 2 cartes à Banco puis les retourne et annonce la valeur des points de chaque main.')}</p>
                            </AccordionItemPanel>
                        </AccordionItem>
                        <AccordionItem  uuid="d">
                            <AccordionItemHeading>
                                <AccordionItemButton>
                                    <h3>{t('Les mains')}</h3>
                                </AccordionItemButton>
                            </AccordionItemHeading>
                            <AccordionItemPanel>
                            <p>{t('Si Punto ou Banco valent 8 ou 9, la distribution des cartes cesse. Le meilleur jeu des deux est gagnant.')}<br/><br/>
                                {t('Si ce n’est pas le cas, le point du Ponte va être déterminé en premier en fonction de la valeur des deux premières cartes.')}<br/><br/>
                                {t('Si le Ponte a entre 0 et 5, il « tire » une carte supplémentaire. Si le Ponte a 6 ou 7, PUNTO, il « reste » et ne tire pas de carte supplémentaire. Si Banco a 7 alors elle ne tire pas de carte supplémentaire. Si Banco a entre 0 et 2 alors elle tire une carte supplémentaire. Si Banco a entre 3 et 6 alors elle tire en fonction de la valeur de la 3ème carte de Punto selon le tableau ci-dessous.')}
                            </p>
                            <a className="photo" href={`${constants.PATH_TO_IMAGES + i18n.language + "/punto/scoring.svg"}`} >
                                <p className="mobile-only">{t('Cliquez pour agrandir')}</p>
                                <img src={`${constants.PATH_TO_IMAGES + i18n.language + "/punto/scoring.svg"}`} alt="Scoring"/>
                            </a>       
                            </AccordionItemPanel>
                        </AccordionItem>
                        <AccordionItem  uuid="e">
                            <AccordionItemHeading>
                                <AccordionItemButton>
                                    <h3>{t('Gains')}</h3>
                                </AccordionItemButton>
                            </AccordionItemHeading>
                            <AccordionItemPanel>
                            <a className="photo" href={`${constants.PATH_TO_IMAGES + i18n.language + "/punto/rewards.svg"}`}  >
                            <p className="mobile-only">{t('Cliquez pour agrandir')}</p>
                            <img src={`${constants.PATH_TO_IMAGES + i18n.language + "/punto/rewards.svg"}`} alt="Rewards"/>
                            </a>  
                            </AccordionItemPanel>
                        </AccordionItem>
                    </Accordion>
                    </div>
                    <div className="games-list block">
                        <h3>{t('D\'autres jeux')}</h3>
                        <GamesList hide="punto"/>
                    </div>
                </div>
            </div>
        </motion.div>
        </div>
        );
    }
}
const pageVariants = {
    initial: {
        opacity: 0,
        position : "relative"

    },
    in: {
        opacity: 1,
        position : "relative"

    },
    out: {
        opacity: 0,
        position : "absolute"
    }
};

const pageTransition = {
    type: "crossfade",
    ease: "anticipate",
    duration: 1
};

const pageStyle = {
    //position: "absolute",
    //position: "absolute",
    width : "100%",
    zIndex : 5
  };
export default withTranslation()(Punto);
