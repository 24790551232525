import i18n from "i18next";
import detector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";


import translationEN from './locales/en/punto.json';
import translationFR from './locales/fr/punto.json';
import translationZH from './locales/zh/punto.json';

// the translations
// (tip move them in a JSON file and import them)
const resources = {
    en: {
        translation: translationEN
    },
    fr: {
        translation: translationFR
    },
    zh: {
        translation: translationZH
    }
};

i18n.on('languageChanged', function (lng) {
    // if the language we switched to is the default language we need to remove the /en from URL
    if (lng === i18n.options.fallbackLng[0]) {
        if (window.location.pathname.includes('/' + i18n.options.fallbackLng[0])) {
            const newUrl = window.location.pathname.replace('/' + i18n.options.fallbackLng[0], '')
            window.location.replace(newUrl)
        }
    }
})

i18n
    .use(detector)
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
        resources,
        debug: true,
        supportedLngs:['en', 'fr', 'zh'],
        fallbackLng: 'fr',
        whitelist: ['en', 'fr', 'zh'],
        detection: {
            order: ['path', 'navigator'],
            lookupFromPathIndex: 0,
            checkWhitelist: true
        },
        saveMissing: true, // send not translated keys to endpoint
        keySeparator: false, // we do not use keys in form messages.welcome
        returnEmptyString: false,
        interpolation: {
            escapeValue: false // react already safes from xss
        }
    });

export default i18n;
