import React, { Component }  from 'react'
import * as constants from '../Constants/Constants'
import { withTranslation } from 'react-i18next';
import PuntoGame from './PuntoGame';

import '../css/tuto-punto.scss'
import i18n from "i18next";

class TutoPunto extends Component {
    constructor(props) {
        super(props);
        this.state = {
            langage : i18n.language
        }
    }

    render() {
        let { t } = this.props;
        return(
            <div id="tuto"
                 style={{
                     transform : this.props.visible ? 'translateY(0vh)' : 'translateY(-100vh)',
                     opacity : this.props.visible ? '1' : '0'
                 }}
            >
                <div className="close-game-modal" onClick={this.props.hide}>{t('Fermer')}</div>
                <PuntoGame langage={this.state.langage}/>
            </div>
        );
    }
}
export default withTranslation()(TutoPunto);