export const events = [
    {
      name : "Soirée spéciale",
      shortDescription : "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
      description : "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent imperdiet dui lacinia odio ornare, ac fermentum justo porta. Mauris a felis ut mi scelerisque consectetur quis ac quam. Proin nunc diam, semper ac auctor eget, dictum nec eros. Cras eu sodales tortor. Nullam enim magna, egestas vel sapien a, mattis posuere nibh. Sed quis felis at magna imperdiet faucibus. Quisque non tincidunt erat, id molestie arcu. Quisque sapien mi, placerat a urna et, aliquam pharetra augu",
      slug : "soiree-spectacle",
      image : "/punto/build/images/illustration-club-1.jpg"
    },
    {
        name : "Soirée événement",
        shortDescription : "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
        description : "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent imperdiet dui lacinia odio ornare, ac fermentum justo porta. Mauris a felis ut mi scelerisque consectetur quis ac quam. Proin nunc diam, semper ac auctor eget, dictum nec eros. Cras eu sodales tortor. Nullam enim magna, egestas vel sapien a, mattis posuere nibh. Sed quis felis at magna imperdiet faucibus. Quisque non tincidunt erat, id molestie arcu. Quisque sapien mi, placerat a urna et, aliquam pharetra augu",
        slug : "soiree-evenement",
        image : "/punto/build/images/illustration-club-2.jpg"
      },
      {
        name : "Tournoi de Poker",
        shortDescription : "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
        description : "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent imperdiet dui lacinia odio ornare, ac fermentum justo porta. Mauris a felis ut mi scelerisque consectetur quis ac quam. Proin nunc diam, semper ac auctor eget, dictum nec eros. Cras eu sodales tortor. Nullam enim magna, egestas vel sapien a, mattis posuere nibh. Sed quis felis at magna imperdiet faucibus. Quisque non tincidunt erat, id molestie arcu. Quisque sapien mi, placerat a urna et, aliquam pharetra augu",
        slug : "tournoi-poker",
        image : "/punto/build/images/illustration-club-4.jpg"
      },
      {
        name : "Tournoi d'utlimate",
        shortDescription : "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
        description : "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent imperdiet dui lacinia odio ornare, ac fermentum justo porta. Mauris a felis ut mi scelerisque consectetur quis ac quam. Proin nunc diam, semper ac auctor eget, dictum nec eros. Cras eu sodales tortor. Nullam enim magna, egestas vel sapien a, mattis posuere nibh. Sed quis felis at magna imperdiet faucibus. Quisque non tincidunt erat, id molestie arcu. Quisque sapien mi, placerat a urna et, aliquam pharetra augu",
        slug : "soiree-ultimate",
        image : "/punto/build/images/illustration-club-3.jpg"
      },
      {
        name : "Boissons offerte",
        shortDescription : "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
        description : "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent imperdiet dui lacinia odio ornare, ac fermentum justo porta. Mauris a felis ut mi scelerisque consectetur quis ac quam. Proin nunc diam, semper ac auctor eget, dictum nec eros. Cras eu sodales tortor. Nullam enim magna, egestas vel sapien a, mattis posuere nibh. Sed quis felis at magna imperdiet faucibus. Quisque non tincidunt erat, id molestie arcu. Quisque sapien mi, placerat a urna et, aliquam pharetra augu",
        slug : "soiree-evenement",
        image : "/punto/build/images/illustration-club-1.jpg"
      },
      {
        name : "Soirée tournoi",
        shortDescription : "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
        description : "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent imperdiet dui lacinia odio ornare, ac fermentum justo porta. Mauris a felis ut mi scelerisque consectetur quis ac quam. Proin nunc diam, semper ac auctor eget, dictum nec eros. Cras eu sodales tortor. Nullam enim magna, egestas vel sapien a, mattis posuere nibh. Sed quis felis at magna imperdiet faucibus. Quisque non tincidunt erat, id molestie arcu. Quisque sapien mi, placerat a urna et, aliquam pharetra augu",
        slug : "soiree-evenement",
        image : "/punto/build/images/illustration-club-2.jpg"
      },
      {
        name : "Etape poker",
        shortDescription : "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
        description : "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent imperdiet dui lacinia odio ornare, ac fermentum justo porta. Mauris a felis ut mi scelerisque consectetur quis ac quam. Proin nunc diam, semper ac auctor eget, dictum nec eros. Cras eu sodales tortor. Nullam enim magna, egestas vel sapien a, mattis posuere nibh. Sed quis felis at magna imperdiet faucibus. Quisque non tincidunt erat, id molestie arcu. Quisque sapien mi, placerat a urna et, aliquam pharetra augu",
        slug : "soiree-evenement",
        image : "/punto/build/images/illustration-club-3.jpg"
      },
      {
        name : "Soirée Punto",
        shortDescription : "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
        description : "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent imperdiet dui lacinia odio ornare, ac fermentum justo porta. Mauris a felis ut mi scelerisque consectetur quis ac quam. Proin nunc diam, semper ac auctor eget, dictum nec eros. Cras eu sodales tortor. Nullam enim magna, egestas vel sapien a, mattis posuere nibh. Sed quis felis at magna imperdiet faucibus. Quisque non tincidunt erat, id molestie arcu. Quisque sapien mi, placerat a urna et, aliquam pharetra augu",
        slug : "soiree-evenement",
        image : "/punto/build/images/illustration-club-4.jpg"
      },
      {
        name : "Evenement dédié",
        shortDescription : "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
        description : "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent imperdiet dui lacinia odio ornare, ac fermentum justo porta. Mauris a felis ut mi scelerisque consectetur quis ac quam. Proin nunc diam, semper ac auctor eget, dictum nec eros. Cras eu sodales tortor. Nullam enim magna, egestas vel sapien a, mattis posuere nibh. Sed quis felis at magna imperdiet faucibus. Quisque non tincidunt erat, id molestie arcu. Quisque sapien mi, placerat a urna et, aliquam pharetra augu",
        slug : "soiree-evenement",
        image : "/punto/build/images/illustration-club-1.jpg"
      },
      {
        name : "Lorem Ipsum",
        shortDescription : "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
        description : "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent imperdiet dui lacinia odio ornare, ac fermentum justo porta. Mauris a felis ut mi scelerisque consectetur quis ac quam. Proin nunc diam, semper ac auctor eget, dictum nec eros. Cras eu sodales tortor. Nullam enim magna, egestas vel sapien a, mattis posuere nibh. Sed quis felis at magna imperdiet faucibus. Quisque non tincidunt erat, id molestie arcu. Quisque sapien mi, placerat a urna et, aliquam pharetra augu",
        slug : "soiree-evenement",
        image : "/punto/build/images/illustration-club-2.jpg"
      },
      {
        name : "Soirée Ipsum",
        shortDescription : "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
        description : "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent imperdiet dui lacinia odio ornare, ac fermentum justo porta. Mauris a felis ut mi scelerisque consectetur quis ac quam. Proin nunc diam, semper ac auctor eget, dictum nec eros. Cras eu sodales tortor. Nullam enim magna, egestas vel sapien a, mattis posuere nibh. Sed quis felis at magna imperdiet faucibus. Quisque non tincidunt erat, id molestie arcu. Quisque sapien mi, placerat a urna et, aliquam pharetra augu",
        slug : "soiree-evenement",
        image : "/punto/build/images/illustration-club-3.jpg"
      },
      {
        name : "Tournoi Lorem",
        shortDescription : "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
        description : "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent imperdiet dui lacinia odio ornare, ac fermentum justo porta. Mauris a felis ut mi scelerisque consectetur quis ac quam. Proin nunc diam, semper ac auctor eget, dictum nec eros. Cras eu sodales tortor. Nullam enim magna, egestas vel sapien a, mattis posuere nibh. Sed quis felis at magna imperdiet faucibus. Quisque non tincidunt erat, id molestie arcu. Quisque sapien mi, placerat a urna et, aliquam pharetra augu",
        slug : "soiree-evenement",
        image : "/punto/build/images/illustration-club-4.jpg"
      },
    
]