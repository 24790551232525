import React, { Component }  from 'react';
import '../css/header.scss'
import { Link } from 'react-router-dom';
import i18n from "i18next";
import * as constants from '../Constants/Constants'
import { withTranslation } from 'react-i18next';



class Header extends Component { 
    constructor(props) {
        super(props);
        this.state = {
            selectedLangage : i18n.language,
            seeLangageSelect : false,
            backButton : this.props.backButton,
            scroll : 'no-scroll',
        }
    }
    componentDidMount(){
        window.addEventListener('scroll', () => {
           let activeClass = 'scroll';
           if(window.scrollY === 0){
               activeClass = 'no-scroll';
           }
           this.setState({ scroll : activeClass });
        });
    }
    showLangages = () => {
        this.setState({
            seeLangageSelect : true
        })
    }
    hideLangages = () => {
        this.setState({
            seeLangageSelect : false
        })
    }
    chooseLangage = (language) => {
        this.setState({
            selectedLangage : language,
            seeLangageSelect : false
        })
        {
            if (window.history.replaceState) {
                let paths = window.location.pathname.split('/');
                paths.shift();

                if(paths[0].length == 2) {
                    if (language === 'fr') {
                        // Remove language
                        paths.shift();
                    }
                    else {
                        paths[0] = language;
                    }
                } else {
                    if (language !== 'fr') {
                        // Add new language
                        paths.unshift(language);
                    }
                }

                window.history.replaceState({}, null, '/' + paths.join('/'));
            }

            i18n.changeLanguage(language);
        }
        const childFrameObj = document.getElementById('tutoPunto');
        if(childFrameObj != null){
            childFrameObj.contentWindow.postMessage({lang: language}, '*');
        }
    }

    displayBackButton = () => {
        let { t } = this.props;
        if(this.state.backButton === "true") {
            return(
                <div className="back-button">
                    <Link to='/'>
                        <img src={`${constants.PATH_TO_IMAGES+"back-gold-arrow.png"}`} alt="Icon"/>
                        <div className="back">{t('Retour')}</div>
                    </Link>
                </div>
            )
        }
    }
   
    render() {
        return(
        <div id="header" 
        className={`${(this.props.backButton === "true" ? 'back' : 'no-back')} ${this.state.scroll}`}>
            <div className="header-content">
               {this.displayBackButton()}
                <div className="logo">
                    <Link to='/'>
                        <img src={`${constants.PATH_TO_IMAGES+"logo-punto-2.svg"}`} alt="logo"/>
                    </Link>
                </div>
                <div id="langage-switch">
                    <div 
                    className={this.state.seeLangageSelect ? 'close' : 'open'}  
                    id="selected-langage"
                    onClick={()=>this.showLangages()}
                    >
                        {this.state.selectedLangage}
                        <img src={`${constants.PATH_TO_IMAGES+"arrow-down.png"}`} alt="Icon"/>
                    </div>
                    <div 
                        className={this.state.seeLangageSelect ? 'open' : 'close'}    
                        id="choose-langage"
                    >
                        <span className="placeholder" onClick={()=> this.hideLangages()}>
                            Choisissez une langue
                            <img className="mobile-only" src={`${constants.PATH_TO_IMAGES+"arrow-down.png"}`} alt="Icon"/></span>
                        <div className="langages-list">
                            <a href="/"
                            className="langage"
                            onClick={(e) => this.chooseLangage('fr')}
                            >Français
                            <span className="mobile-only">&laquo; Bienvenue ! &raquo; </span>
                            </a>
                            <a href="/zh"
                            className="langage"
                            onClick={() => this.chooseLangage('zh')}
                            >中文
                            <span className="mobile-only">&ldquo; 欢迎 &rdquo; </span>
                            </a>
                            <a href="/en"
                            className="langage"
                            onClick={() => this.chooseLangage('en')}
                            >
                                English
                                <span className="mobile-only">&ldquo; Welcome ! &rdquo; </span>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        );
    }
}
export default withTranslation()(Header);
