import React, { Component }  from 'react';
import { Link } from 'react-router-dom';
import * as constants from '../Constants/Constants'
import { withTranslation } from 'react-i18next';
import '../css/games-list.scss'
import ScrollAnimation from "react-animate-on-scroll";

class GamesList extends Component { 
    constructor(props) {
        super(props);
        this.state = {
            gameToHide : this.props.hide
        }
    }
    render() {
        let { t } = this.props;
        return(
        <div id="games-list">
            {this.state.gameToHide !== 'punto' &&
                <div className="game-link">
                    <Link to={{pathname : '/game/punto-banco'}}>
                    <div className="image">
                        <h4>{t('Punto banco')}</h4>
                        <img className="game-picture" src={`${constants.PATH_TO_IMAGES+"punto-picture.jpg"}`} alt="{t('Punto banco')}"/>
                        <div className="overlay">
                            {/*
                            <span>{t('Le Punto Banco est un jeu facile d\'accès hérité du Baccara')}</span>
                            */}
                            <span className="see-more">{t('Voir plus')} <img src={`${constants.PATH_TO_IMAGES+"arrow-next.png"}`} alt="Icon see more"/></span>
                        </div>
                    </div>
                    <div className="game-text">
                        {/*
                        <h5>{t('Simple à apprendre, intense à jouer')}</h5>
                        */}
                    </div>
                </Link>
                </div>
            }
            {this.state.gameToHide !== 'black-jack' &&
                <div className="game-link">
                <Link to={{pathname : '/game/black-jack'}}>
                    <div className="image">
                        <h4>{t('Black Jack')}</h4>
                        <img className="game-picture" src={`${constants.PATH_TO_IMAGES+"blackJack.jpg"}`} alt="Black Jack"/>
                        <div className="overlay">
                            {/*
                            <span>{t('Description Black Jack')}</span>
                            */}
                            <span className="see-more">{t('Voir plus')} <img src={`${constants.PATH_TO_IMAGES+"arrow-next.png"}`} alt="Icon see more"/></span>
                        </div>
                    </div>
                    <div className="game-text">
                        {/*
                        <h5>{t('Description Black Jack')}</h5>
                        */}
                    </div>
                </Link>
                </div>
            }
            {this.state.gameToHide !== 'ultimate-hold-em' &&
                <div className="game-link">
                <Link to={{pathname : '/game/ultimate-hold-em'}}>
                    <div className="image">
                        <h4>{t('Ultimate Poker')}</h4>
                        <img className="game-picture" src={`${constants.PATH_TO_IMAGES+"ultimate-poker.jpg"}`} alt="{t('Ultimate Poker')}"/>
                        <div className="overlay">
                            {/*
                            <span>{t('L\'Ultimate Poker propose une nouvelle expérience qui revisite le poker traditionnel')}</span>
                            */}
                            <span className="see-more">{t('Voir plus')} <img src={`${constants.PATH_TO_IMAGES+"arrow-next.png"}`} alt="Icon see more"/></span>
                        </div>
                    </div>
                    <div className="game-text">
                        {/*
                        <h5>{t('Osez défier le croupier')}</h5>
                            */}
                    </div>
                </Link>
                </div>
            }
            {this.state.gameToHide !== 'texas-hold-em' &&
            <div className="game-link">
                <Link to={{pathname : '/game/texas-hold-em'}}>
                    <div className="image">
                        <h4>{t('Texas Hold\'em')}</h4>
                        <img className="game-picture" src={`${constants.PATH_TO_IMAGES+"holdem.jpg"}`} alt="{t('Texas Hold'em')}"/>
                        <div className="overlay">
                            {/*
                            <span>{t('Le Multicolore est surnommé également « la Roulette Parisienne »')}</span>
                            */}
                            <span className="see-more">{t('Voir plus')} <img src={`${constants.PATH_TO_IMAGES+"arrow-next.png"}`} alt="Icon see more"/></span>
                        </div>
                    </div>
                    <div className="game-text">
                        {/*
                        <h5>{t('Même principe que la roulette anglaise')}</h5>
                        */}
                    </div>
                </Link>
            </div>
            }
        </div>
        );
    }
}
export default withTranslation()(GamesList);