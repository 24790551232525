import React, { Component }  from 'react'
import * as constants from '../Constants/Constants'
import { withTranslation } from 'react-i18next';
import request from 'superagent'
import $ from "jquery"


import i18n from "i18next";

class PuntoGame extends Component {
    constructor (props) {
        super(props)
        this.state = {
            gameUrl : ''
        }
    }
    componentDidMount () {
        var that = this;
        $.ajax({
            url: '/punto/game-url',
            type : 'GET',
            data: {
                'lang': that.props.langage
            },
            success: function(resp) {

                console.log(resp);
                let url = JSON.parse(resp).game_url;
                that.setState({gameUrl: url})
            },
            error: function(requestObject, error, errorThrown) {
                console.log(errorThrown);
            }
        });
    }
    render() {
        return(
            <iframe ref={this.frame}
                    id="tutoPunto"
                    title="Tutoriel Punto Banco"
                    src={this.state.gameUrl}
                    >
            </iframe>
        )
    }
}

export default withTranslation()(PuntoGame);