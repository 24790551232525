import React, { Component }  from 'react'
import Header from "./Header"
import { motion } from "framer-motion";
import EventsList from './EventsList'
import { withTranslation } from 'react-i18next';
import '../css/event.scss'

class Event extends Component { 

    componentDidMount() {
      window.scrollTo(0, 0);
    }

    render() {
        let { t } = this.props;
        const { image, name, description } = this.props.event;
        return(
            <motion.div
            style={pageStyle}
            initial="initial"
            animate="in"
            exit="out"
            variants={pageVariants}
            transition={pageTransition}
            >
        <div className="event">
            <Header backButton="true"/>
            <div className="content" style={{
                background: 'url('+image+')', 
                backgroundSize : "100%", 
                backgroundRepeat : "no-repeat"
                }}>
                <div className="mask"></div>
                <div className="content-text">
                <h1>{name}</h1>
                <div className="description">
                    {description}
                </div>
                </div>
            </div>
            <div className="events-list">
                <h3>{t('Mais aussi...')}</h3>
                <EventsList light="true" />
            </div>
        </div>
        </motion.div>

        );
    }
}
const pageVariants = {
    initial: {
      opacity: 0,
      y: "100vh",
    },
    in: {
      opacity: 1,
      y: 0,
    },
    out: {
      opacity: 0,
      y: "-100vh",
    }
  };
  
  const pageTransition = {
    type: "crossfade",
    ease: "linear",
    duration: 0.4
  };
  
  const pageStyle = {
    //position: "absolute",
    width : "100%",
    zIndex : 5
  };
export default withTranslation()(Event);