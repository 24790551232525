import React, { Component }  from 'react'
import Header from './Header'
import GamesList from './GamesList'
import { Link } from 'react-router-dom';
import { motion } from "framer-motion";
import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
} from 'react-accessible-accordion';
import i18n from "i18next";
import * as constants from '../Constants/Constants'
import { withTranslation } from 'react-i18next';
import '../css/game.scss'
import {Helmet} from "react-helmet";


class Multicolore extends Component {

    componentDidMount() {
        setTimeout(function(){
            window.scrollTo({
                top: 0,
                left: 0,
                behavior: 'smooth'
            });
        }, 100);
        if(document.getElementById("punto-footer") !== null) {
            let pfooter = document.getElementById("punto-footer")
            pfooter.style.display = "block";
        }
        document.body.classList.remove("no-footer");
    }

    render() {
        let { t } = this.props;
        return(
            <div>
                <Helmet htmlAttributes={{ lang : i18n.language }}>
                    <title>{t('Découvrez le Multicolore- Punto Club Paris')}</title>
                    <meta name="description" content={t('Familiarisez-vous avec les règles du Poker 21. 2 tables de jeux y sont dédiées au Punto Club.')} />
                </Helmet>
                <motion.div
                    style={pageStyle}
                    initial="initial"
                    animate="in"
                    exit="out"
                    variants={pageVariants}
                    transition={pageTransition}
                >
                        <div id="poker-21">
                            <Header backButton="true"/>
                            <div className="contact">
                                <Link to={{ pathname: '/coordinates',  state: { from: this.props.location.pathname } }} >
                                    <img src={`${constants.PATH_TO_IMAGES+"gold-geoloc.png"}`} alt="Icon" />
                                    <span>{t('S\'y rendre')}</span><span>/</span><span>{t('Contact')}</span>
                                </Link>
                            </div>
                            <div className="top">
                                <h1>{t('Le Multicolore')}</h1>
                                <h3>{t('Origine du jeu')}</h3>
                                <p>{t('C’est un président français qui est à l’origine du Multicolore au début du 20e siècle ! Très exactement de Raymond Poincaré, qui demanda à Paul Painlevé, mathématicien et homme politique de lui concevoir un nouveau jeu afin de stopper les jeux clandestins parisiens.')}</p>
                            </div>
                            <div className="content">
                                <div className="block icons">
                                    <div id="tables">
                                        <img src={`${constants.PATH_TO_IMAGES+"icon-tables.svg"}`} alt="Icon tables" />
                                        <span>{t('2 tables de jeu')}</span>
                                    </div>
                                    <div id="open">
                                        <img src={`${constants.PATH_TO_IMAGES+"icon-hours.svg"}`} alt="Icon hours" />
                                        <span>{t('De 21h à 5h 7j/7')}</span>
                                    </div>
                                </div>
                                <img className="illustration-game" src={`${constants.PATH_TO_IMAGES+"multicolore-game.jpg"}`} alt="Poker 21 illustration" />
                                <h3>{t('Les règles')}</h3>
                                <div className="accordion">
                                    <Accordion allowMultipleExpanded preExpanded={['a']}>
                                        <AccordionItem  uuid="a">
                                            <AccordionItemHeading>
                                                <AccordionItemButton>
                                                    <h3>{t('Composition du jeu')}</h3>
                                                </AccordionItemButton>
                                            </AccordionItemHeading>
                                            <AccordionItemPanel>
                                                <p>{t('Mélange de jeu de hasard et d’adresse le jeu se présente comme un plateau contenant')} :
                                                </p>
                                                <ul>
                                                    <li>{t('25 alvéoles de 5 couleurs différentes (6 rouges, 6 vertes, 6 jaunes, 6 blanches et 1 étoile de couleur bleue).')}</li>
                                                    <li>{t('Les 6 alvéoles d’une même couleur ont')} : {t('une fois le chiffre 4, trois autres le chiffre 3 et les deux dernières le chiffre 2.')}</li>
                                                    <li>{t('L’étoile représente le numéro 24')}</li>
                                                </ul>
                                            </AccordionItemPanel>
                                        </AccordionItem>
                                        <AccordionItem  uuid="b">
                                            <AccordionItemHeading>
                                                <AccordionItemButton>
                                                    <h3>{t('Déroulement du jeu')}</h3>
                                                </AccordionItemButton>
                                            </AccordionItemHeading>
                                            <AccordionItemPanel>
                                                <p>{t('Amateur de roulette anglaise, vous ne serez pas déboussolés !')}</p>
                                                <p>{t('Les choix sont multiples')} : <strong>{t('miser sur une ou plusieurs des 4 couleurs et/ou bien sur l’étoile')}</strong></p>
                                                <ul>
                                                    <li>{t('Sélectionnez une, plusieurs couleurs ou directement l’étoile pour mise')}</li>
                                                    <li>{t('Le croupier lance la boule et annonce son fameux « Rien ne va plus ! »')}</li>
                                                    <li>{t('Plus aucune mise ne peut être déposée ou retirée du tapis')}</li>
                                                    <li>{t('Quand la boule s’arrête, le croupier annonce le résultat')} : {t('la couleur et la multiplication')}</li>
                                                    <li>{t('On hurle sa victoire ou pleure sa défaite en fonction du résultat !')}</li>
                                                </ul>
                                                <br/>
                                                <p><strong>{t('EXEMPLE')}</strong></p>
                                                <p>{t('Quand la boule s’arrête sur l’alvéole verte 4, si vous avez misé sur la verte')} : {t('votre mise est payée 4 fois.')}
                                                </p>
                                            </AccordionItemPanel>
                                        </AccordionItem>
                                    </Accordion>
                                </div>
                                <div className="games-list block">
                                    <h3>{t('D\'autres jeux')}</h3>
                                    <GamesList hide="multicolore"/>
                                </div>
                            </div>
                        </div>
                </motion.div>
            </div>
        );
    }
}
const pageVariants = {
    initial: {
        opacity: 0,
        position : "relative"

    },
    in: {
        opacity: 1,
        position : "relative"

    },
    out: {
        opacity: 0,
        position : "absolute"
    }
};

const pageTransition = {
    type: "crossfade",
    ease: "anticipate",
    duration: 1
};

const pageStyle = {
    //position: "absolute",
    width : "100%"
};
export default withTranslation()(Multicolore);
