import React, { Component } from 'react';
import Header from './Header';
import GamesList from './GamesList';
import TutoPunto from './TutoPunto';
import EventsList from './EventsList'
import { motion } from "framer-motion";
import { Link } from 'react-router-dom';
import ScrollAnimation from 'react-animate-on-scroll';
import { withTranslation } from 'react-i18next';
import * as constants from '../Constants/Constants'
import '../css/home.scss'
import {Helmet} from "react-helmet";
import i18n from "i18next";

class Home extends Component {

    constructor(props){
      super(props);
      this.club = React.createRef();
      this.homepage = React.createRef();

      var d1 = Date.parse("2022-08-24");
      var d2 = Date.now();
      console.log(d1, d2);
      console.log(d2>d1);
      let isClosedModal = true;
      if (d2>d1) {
        isClosedModal = false;
      }
      console.log(isClosedModal);

      this.state = {
        scroll : "no-scroll",
        visiblePuntoGame : false,
        visibleClosed : isClosedModal,
      }
    }

    componentDidMount() {
      window.scrollTo(0, 0);
      if(document.getElementById("punto-footer") !== null) {
        let pfooter = document.getElementById("punto-footer")
        pfooter.style.display = "block";
      }
      document.body.classList.remove("no-footer");
      window.addEventListener('scroll', () => {
        let activeClass = 'scroll';
        if(window.scrollY === 0){
          activeClass = 'no-scroll';
        }
        this.setState({ scroll : activeClass });
      });
    }

    showPuntoGame = () => {
      this.setState({
        visiblePuntoGame : true
      })
    }

    hidePuntoGame = () => {
      this.setState({
        visiblePuntoGame : false
      })
    }

    hideModalClosed = () => {
      this.setState({
        visibleClosed : false
      })
    }

    scrollToClub = () => {
      this.setState({
        scroll : "scroll"
      })
      this.club.current.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      });
    }

    displayMagnifier = () => {
      return(
        <svg xmlns="http://www.w3.org/2000/svg" width="69.812" height="68.879" viewBox="0 0 69.812 68.879">
          <g>
            <path fill="rgb(217,185,89)" fillRule="evenodd" d="M31.14287739 18.23540346H29.2613412v10.69274468H18.44250822v1.88695495h10.818833v10.69274468h1.88153617V30.81510309h10.97562767v-1.88695495H31.14287739V18.23540346z"/>
            <path fill="rgb(217,185,89)" fillRule="evenodd" d="M51.61242676 8.7253418c5.71435547 5.64038086 8.86138916 13.140625 8.86138916 21.11938476.01239013 7.61865235-2.92419434 14.94433594-8.19110108 20.43432617l17.5291748 17.25341797-1.31799316 1.34643555-17.55096435-17.27514648c-11.972229 11.02319335-30.44696045 10.74243164-42.0805664-.6394043C3.14703368 45.32373047 0 37.82373047 0 29.84472657 0 21.8659668 3.1470337 14.36572265 8.86157227 8.7253418c11.78637695-11.63378907 30.96429443-11.63378907 42.75085449 0zm-49.730896 21.11914062c0 7.44335938 2.99212647 14.57202149 8.29980469 19.7746582 11.05871582 10.91552735 29.05212402 10.91552735 40.11120605 0 5.3076172-5.20263671 8.29974366-12.33129882 8.29974366-19.7746582 0-7.44311523-2.99212647-14.57202148-8.29974366-19.7746582-11.1553955-10.9152832-28.95581054-10.9152832-40.11120605 0-5.30767822 5.20263672-8.29980469 12.33154297-8.29980469 19.7746582z"/>
          </g>
        </svg>
      )
    }
   
    render(){
      let { t } = this.props;
        return (
          <div>
            <Helmet htmlAttributes={{ lang : i18n.language }}>
              <title>{t('PUNTO CLUB – Le premier club de jeux à Paris du groupe Partouche')}</title>
              <meta name="description" content={t('A deux pas des Champs-Elysées, vivez la meilleure expérience de jeu de la capitale. Punto Banco, Poker 21, Ultimate Poker. Ouvert de 21h à 6H.')} />
            </Helmet>
            <motion.div
                style={pageStyle}
                initial="initial"
                animate="in"
                exit="out"
                variants={pageVariants}
                transition={pageTransition}
                >
            <div id="home"
                 ref={this.homepage}
                 className={`${this.state.scroll}`}
                >
              <Header backButton="true"/>
              <div className="contact">
                <Link to={{ pathname: '/coordinates', state: { from: this.props.location.pathname } }} >
                  <img src={`${constants.PATH_TO_IMAGES+"gold-geoloc.png"}`} alt="Icon" />
                  <span>{t('S\'y rendre')}</span><span>/</span><span>{t('Contact')}</span>
                </Link>
              </div>
          <img  style={{
            width : this.state.visibleClosed ? '100vw' : '100vw',
            height : this.state.visibleClosed ? '100vh' : '0',
            opacity :  this.state.visibleClosed ? '1' : '0',
            objectFit: 'contain',
            position: 'fixed',
            zIndex: 99999,
            overflow:'hidden',
            transition: 'height 1.3s,width 1.8s, opacity 1.8s',
          }}
                                               className="closedImg" src={window.innerWidth <= 760 ? `${constants.PATH_TO_IMAGES+"closedpuntoverticale.jpg"}` : `${constants.PATH_TO_IMAGES+"closedpunto.jpg"}`}  onClick={() => this.hideModalClosed()} />

              <div id="home-top">
                <div className="animated-mask"></div>
                <div className="mask"></div>
                <div className="home-top-content">
                  <h1>
                    <span className="title-one">
                      {t('Le club de jeux à Paris By Partouche')}
                    </span>
                    <span className="title-two">{t('11 rue de Berri, 75008 Paris - Ouvert tous les jours de 10H à 06H')}</span>
                  </h1>
                  <div
                  className="discover"
                  onClick={() => this.scrollToClub()}
                  >
                    <span>{t('Découvrir')}</span>
                    <img src={`${constants.PATH_TO_IMAGES+"long-arrow-down.svg"}`} alt="Icon arrow down"/>
                  </div>
                </div>
                <div id="scroll-to-club" ref={this.club}></div>
                <h2>{t('Le Club')}</h2>
              </div>
              <div className="home-content" >
                <div id="club">
                  <div className="club-highlight">
                    <div className="catchphrase">
                        {t('Le rendez-vous des passionnés du jeu')}
                    </div>
                  </div>
                  <div className="club-description">
                    <div className="unique-place">
                      <h4>{t('Une adresse Premium en plein cœur de Paris')}</h4>
                      <p>
                        <span>{t('A 100 mètres de la mythique avenue des Champs-Elysées, cet établissement de 300m² propose, dans son cadre feutré, le meilleur du jeu au cœur de Paris. ')}</span>
                        <span>{t('8 tables de jeux sont à disposition des joueurs qui pourront vibrer autour de trois jeux traditionnels')} : {t('le Punto Banco, le Poker 21 et l\'Ultimate Poker.')}<br/></span><br/>
                        <span>{t('Un espace de restauration, un bar et un fumoir sont à la disposition des joueurs. Les sensations de plaisir et de bien-être sont garanties !')}</span>
                      </p>
                      {/*
                      <p className="bold">{t('Le Punto Club est le seul établissement de la capitale à être ouvert 20h sur 24h')}</p>
                      */}
                    </div>

                    <div className="icons">
                      <div id="tables">
                        <img src={`${constants.PATH_TO_IMAGES+"icon-tables.svg"}`} alt="Icon tables" />
                        <span>{t('8 tables de jeu')}</span>
                      </div>
                      {/*<div id="bar">
                        <span>{t('1 bar')}</span>
                        <img src={`${constants.PATH_TO_IMAGES+"icon-bar.svg"}`}alt="Icon bar" />
                      </div>*/}
                      <div id="open">
                        <img src={`${constants.PATH_TO_IMAGES+"icon-hours.svg"}`} alt="Icon hours" />
                        <span>{t('Ouvert 7j/7')}</span>
                      </div>
                    </div>

                    <div className="contact-text">
                      <h4>{t('Notre ambition')} : {t('vous divertir')}</h4>
                      <p>{t('Seul ou entre amis, venez vous plonger dans l\'univers envoûtant du Punto Club. Parce que l\'émotion vient aussi par le jeu, nous mettons tout en œuvre pour rendre votre expérience mémorable.')}</p>
                      <p>{t('Une envie particulière ? Une question ?')}<br/>
                      <Link to={{ pathname: '/coordinates', state: { from: this.props.location.pathname } }} >
                        <span className="contact-link">{t('Contactez-nous !')}</span>
                      </Link>
                      </p>
                    </div>
                  </div>
                  <div className="club-illustrations">
                        <img className="illustration photo" src={`${constants.PATH_TO_IMAGES+"illustration-club-1-small.jpg"}`} alt="Punto club"/>

                        <img className="illustration photo" src={`${constants.PATH_TO_IMAGES+"illustration-club-2-small.jpg"}`} alt="Punto club"/>

                        <img className="illustration photo" src={`${constants.PATH_TO_IMAGES+"illustration-club-3-small.jpg"}`} alt="Punto club"/>

                        <img className="illustration photo" src={`${constants.PATH_TO_IMAGES+"illustration-club-4-small.jpg"}`} alt="Punto club"/>

                        <img className="illustration photo" src={`${constants.PATH_TO_IMAGES+"illustration-club-8-small.jpg"}`} alt="Punto club"/>

                        <img className="illustration photo" src={`${constants.PATH_TO_IMAGES+"illustration-club-6-small.jpg"}`} alt="Punto club"/>

                        <img className="illustration photo" src={`${constants.PATH_TO_IMAGES+"illustration-club-7-small.jpg"}`} alt="Punto club"/>

                        <img className="illustration photo" src={`${constants.PATH_TO_IMAGES+"illustration-club-5-small.jpg"}`} alt="Punto club"/>

                    </div>

                </div>
                <div id="games">
                  <h2>{t('Les jeux')}</h2>
                  <GamesList hide="none"/>
                </div>
                <div id="tuto-punto">
                  <div className="mask"></div>
                  <div className="catchphrase">
                    <p>{t('Apprenez les bases du punto banco en moins de cinq minutes')}</p>
                    <div className="link">
                      <span className="open-game" onClick={this.showPuntoGame}>{t('Jouez gratuitement')}</span>
                      <img src="/punto/build/images/arrow-next.png" alt="Icon see more"/>
                    </div>
                  </div>
                  <TutoPunto
                      visible={this.state.visiblePuntoGame}
                      hide={this.hidePuntoGame}
                  />
                </div>
                {/*<div id="events">
                  <EventsList light="true"/>
                </div>*/}
                <div id="partners">
                  <h4>{t('Nos partenaires')}</h4>
                  <div className="partners-logos">
                    <img src={`${constants.PATH_TO_IMAGES+"logo-cfc.jpg"}`} alt="Logo Cercle France Chine"/>
                    <img src={`${constants.PATH_TO_IMAGES+"logo-efcgo.jpg"}`} alt="Logo EFCGO"/>
                    <img src={`${constants.PATH_TO_IMAGES+"logo-caocao.jpg"}`} alt="Logo CAOCAO"/>
                    <img src={`${constants.PATH_TO_IMAGES+"logo-uto.jpg"}`} alt="Logo UTO"/>
                    <img src={`${constants.PATH_TO_IMAGES+"logo-moutai.png"}`} alt="Logo MOUTAI"/>
                    <img src={`${constants.PATH_TO_IMAGES+"logo-paris-look.jpg"}`} alt="Logo Paris Look"/>
                  </div>
                </div>
              </div>
            </div>
            </motion.div>
            </div>
        );
    }
}

const pageVariants = {
  initial: {
    opacity: 0,
    position : "relative"

  },
  in: {
    opacity: 1,
    position : "relative"

  },
  out: {
    opacity: 0,
    position : "absolute"
  }
};

const pageTransition = {
  type: "crossfade",
  ease: "anticipate",
  duration: 1
};

const pageStyle = {
  //position: "absolute",
  width : "100%",
  zIndex : 5
};
const imageStyle = {
  width : "90%",
  height : "95%"
};
  export default withTranslation()(Home);
