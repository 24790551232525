import React, { Component }  from 'react'
import Header from './Header'
import GamesList from './GamesList'
import { Link } from 'react-router-dom';
import { motion } from "framer-motion";
import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
} from 'react-accessible-accordion';
import * as constants from '../Constants/Constants'
import { withTranslation } from 'react-i18next';
import i18n from "i18next";
import '../css/game.scss'
import {Helmet} from "react-helmet";


class UltimatePoker extends Component { 
    
    componentDidMount() {
        setTimeout(function(){
            window.scrollTo({
                top: 0,
                left: 0,
                behavior: 'smooth'
            });
        }, 100);
        if(document.getElementById("punto-footer") !== null) {
            let pfooter = document.getElementById("punto-footer")
            pfooter.style.display = "block";
        }
        document.body.classList.remove("no-footer");
      }

    render() {
        let { t } = this.props;
        return(
        <div>
            <Helmet htmlAttributes={{ lang : i18n.language }}>
                <title>{t('Vivez l’Ultimate Poker- Punto Club Paris')}</title>
                <meta name="description" content={t('Au Punto Club, retrouvez 2 tables de jeu pour vibrer à l\'Ultimate Poker. En attendant, découvrez le jeu et ses règles !')} />
            </Helmet>
        <motion.div
            style={pageStyle}
            initial="initial"
            animate="in"
            exit="out"
            variants={pageVariants}
            transition={pageTransition}
            >
            <div id="ultimate-poker">
                <Header backButton="true"/>
                <div className="top">
                <h1>{t('L\'ultimate Poker')}</h1>
                    <h3>{t('L\'Ultimate Poker, c\'est quoi ?')}</h3>
                    <p>{t('L\'Ultimate Poker est une variante du Texas Hold\'em. Les joueurs ne s\'affrontent pas entre eux, mais font face à un adversaire commun')} : {t('la banque !')}</p>
                </div>
                <div className="contact">
                  <Link to={{ pathname: '/coordinates',  state: { from: this.props.location.pathname } }} >
                  <img src={`${constants.PATH_TO_IMAGES+"gold-geoloc.png"}`} alt="Icon" />
                    <span>{t('S\'y rendre')}</span><span>/</span><span>{t('Contact')}</span>
                    </Link>
                  </div>
                <div className="content">
                <div className="block icons">
                        <div id="tables">
                            <img src={`${constants.PATH_TO_IMAGES+"icon-tables.svg"}`} alt="Icon tables" />
                            <span>{t('2 tables de jeu')}</span>
                        </div>
                        <div id="open"> 
                            <img src={`${constants.PATH_TO_IMAGES+"icon-hours.svg"}`} alt="Icon hours" />
                            <span>{t('De 21H à 5h 7j/7')}</span>
                        </div>
                    </div>
                    <img className="illustration-game" src={`${constants.PATH_TO_IMAGES+"game-ultimate-poker.jpg"}`} alt="Poker 21 illustration" />
                    <h3>{t('Les règles')}</h3>
                    <div className="accordion">
                    <Accordion allowMultipleExpanded preExpanded={['a']}>
                        <AccordionItem uuid="a">
                            <AccordionItemHeading>
                                <AccordionItemButton>
                                    <h3>{t('Objectif')}</h3>
                                </AccordionItemButton>
                            </AccordionItemHeading>
                            <AccordionItemPanel>
                                <p>
                                    {t('Ce jeu se dispute avec 52 cartes. Le joueur doit tenter d’obtenir la main gagnante contre la banque, avec la combinaison la plus élevée. Le but du jeu est donc de réaliser la meilleure combinaison de 5 cartes en utilisant 2 cartes associées à 3 des 5 cartes communes de la banque.')}
                                </p>
                            </AccordionItemPanel>
                        </AccordionItem>
                        <AccordionItem  uuid="b">
                            <AccordionItemHeading>
                                <AccordionItemButton>
                                    <h3>{t('Valeurs des mains')}</h3>
                                </AccordionItemButton>
                            </AccordionItemHeading>
                            <AccordionItemPanel>
                                <p>{t('La valeur des combinaisons est la même pour le Texas Hold\'em.')}</p>
                                    <h4>{t('Classement de la main la plus faible à la plus forte')}</h4>
                                    <h5>{t('Hauteur')}</h5>
                                    <img src={`${constants.PATH_TO_IMAGES+"hauteur.png"}`} alt="High card"/>
                                    <h5>{t('Paire')}</h5>
                                    <img src={`${constants.PATH_TO_IMAGES+"paire.png"}`} alt="Pair"/>
                                    <h5>{t('2 paires')}</h5>
                                    <img src={`${constants.PATH_TO_IMAGES+"deux-paires.png"}`} alt="Two pairs"/>
                                    <h5>{t('Brelan')}</h5>
                                    <img src={`${constants.PATH_TO_IMAGES+"brelan.png"}`} alt="Three of a kind"/>
                                    <h5>{t('Quinte')}</h5>
                                    <img src={`${constants.PATH_TO_IMAGES+"quinte.png"}`} alt="Straight"/>
                                    <h5>{t('Couleur')}</h5>
                                    <img src={`${constants.PATH_TO_IMAGES+"couleur.png"}`} alt="Flush"/>
                                    <h5>{t('Full')}</h5>
                                    <img src={`${constants.PATH_TO_IMAGES+"full.png"}`} alt="Full house"/>
                                    <h5>{t('Carré')}</h5>
                                    <img src={`${constants.PATH_TO_IMAGES+"carre.png"}`} alt="Four of a kind"/>
                                    <h5>{t('Quinte Flush')}</h5>
                                    <img src={`${constants.PATH_TO_IMAGES+"quinte-flush.png"}`} alt="Straight flush"/>
                                    <h5>{t('Quinte Flush Royale')}</h5>
                                    <img src={`${constants.PATH_TO_IMAGES+"quinte-flush-royale.png"}`} alt="Royal flush"/>
                            </AccordionItemPanel>
                        </AccordionItem>
                        <AccordionItem  uuid="c">
                            <AccordionItemHeading>
                                <AccordionItemButton>
                                    <h3>{t('Déroulement du jeu')}</h3>
                                </AccordionItemButton>
                            </AccordionItemHeading>
                            <AccordionItemPanel>
                            <p className="title"><span className="diamond">&#x2B27;</span>{t('Miser')}</p>
                            <p>{t('Pour jouer, le joueur doit répartir sa mise à égalité sur les cases « Mise » et « Blinde » du tapis et miser de façon optionnelle sur la case « Bonus ». Ces mises ne peuvent ensuite ni être enlevées ni modifiées, le joueur doit donc bien y penser dès le début.')}</p>
                                <a className="photo" href={`${constants.PATH_TO_IMAGES + i18n.language +"/ultimate-poker/bets-new.svg"}`} >
                                <p className="mobile-only">{t('Cliquez pour agrandir')}</p>
                                <img className="mid-size" src={`${constants.PATH_TO_IMAGES + i18n.language +"/ultimate-poker/bets-new.svg"}`} alt="Bets"/>
                                </a>
                            <p className="title"><span className="diamond">&#x2B27;</span>{t('La donne')}</p>
                            <p>{t('Une fois la mise faite, le croupier distribue 2 cartes à chaque joueur de la table (jusqu’à 7) et à la banque, face cachée. Les cartes peuvent être consultées par les joueurs.')}</p>
                                <a className="photo" href={`${constants.PATH_TO_IMAGES + i18n.language +"/ultimate-poker/deal.svg"}`}>
                                <p className="mobile-only">{t('Cliquez pour agrandir')}</p>
                                <img src={`${constants.PATH_TO_IMAGES + i18n.language +"/ultimate-poker/deal.svg"}`} alt="Deal"/>
                                </a>

                            <p className="title"><span className="diamond">&#x2B27;</span>{t('Premier tour d\'enchères')}</p>
                            <p>{t('A ce moment, plusieurs possibilités sont offertes au joueur')} : {t('soit mettre 3 à 4 fois le montant de la case « Mise » dans la case « Jouer », ou passer (Checker). Si le joueur a parié 3 à 4 fois sur la case « Jouer » alors il ne pourra pas parier sur cette case durant les prochains tours.')}</p>
                             <a className="photo" href={`${constants.PATH_TO_IMAGES + i18n.language +"/ultimate-poker/first-round.svg"}`} >
                                <p className="mobile-only">{t('Cliquez pour agrandir')}</p>
                                <img className="mid-size" src={`${constants.PATH_TO_IMAGES + i18n.language +"/ultimate-poker/first-round.svg"}`} alt="Poker auction : First round"/>
                            </a>
                            <p className="title"><span className="diamond">&#x2B27;</span>{t('Le flop')}</p>
                            <p>{t('Après ce 1er tour, le croupier retourne 3 cartes communes. Si le joueur n’avait pas encore misé sur la case « Jouer », il peut mettre deux fois le montant de la case « Mise » dans la case « Jouer », ou passer à nouveau.')}</p>
                                <a className="photo" href={`${constants.PATH_TO_IMAGES + i18n.language +"/ultimate-poker/flop.svg"}`} >
                                <p className="mobile-only">{t('Cliquez pour agrandir')}</p>
                                <img src={`${constants.PATH_TO_IMAGES + i18n.language +"/ultimate-poker/flop.svg"}`} alt="Flop"/>
                                </a>

                            <p className="title"><span className="diamond">&#x2B27;</span>{t('Turn et river')}</p>
                            <p>{t('Le croupier retourne les 2 cartes communes restantes (Turn et la River). Si le joueur n’avait pas encore misé sur la case « Jouer », il a le choix entre mettre une fois le montant de la case « Mise » dans la case « Jouer », ou se coucher (fold). Le joueur perd alors toutes les mises effectuées jusque-là, à l’exception de celle placée sur la case « Bonus ».')}</p>
                                <a className="photo" href={`${constants.PATH_TO_IMAGES + i18n.language +"/ultimate-poker/turn-and-river.svg"}`} >
                                <p className="mobile-only">{t('Cliquez pour agrandir')}</p>
                                <img src={`${constants.PATH_TO_IMAGES + i18n.language +"/ultimate-poker/turn-and-river.svg"}`} alt="Turn and river"/>
                                </a>

                            <p className="title"><span className="diamond">&#x2B27;</span>{t('Fin du coup')}</p>
                            <p>{t('Le croupier retourne les 2 cartes de la Banque et annonce sa main. La Banque a besoin d’une paire ou mieux pour se qualifier. Dans le cas inverse, « Mise » revient au joueur.')}</p>
                            <p>{t('Si la main du joueur est plus forte que celle de la Banque, « Mise » et « Jouer » sont payées à égalité ; « Blinde » est payée si le joueur a au minimum une suite, autrement elle sera rendue au joueur.')} </p>
                            <p>{t('Si la Banque a une meilleure main que le joueur, ses jetons sur « Mise », « Jouer » et « Blinde » sont perdus.')}</p>
                            <p>{t('Si la main du joueur est égale à celle de la Banque, les montants « Mise », « Jouer » et « Blinde » sont rendues au joueur.')}</p>

                            <p className="title"><span className="diamond">&#x2B27;</span>{t('Le bonus')}</p>
                            <p>{t('Le joueur décroche le « Bonus » uniquement s’il touche un brelan ou mieux, peu importe la main de la Banque. Celui-ci est optionnel et peut augmenter considérablement les gains.')}</p>

                            <p className="title"><span className="diamond">&#x2B27;</span>{t('Tableaux de paiement de "BLINDE" et du "BONUS"')}</p>
                                <a className="photo" href={`${constants.PATH_TO_IMAGES + i18n.language +"/ultimate-poker/rewards.svg"}`}>
                                <p className="mobile-only">{t('Cliquez pour agrandir')}</p>
                                <img src={`${constants.PATH_TO_IMAGES + i18n.language +"/ultimate-poker/rewards.svg"}`} alt="Blind and bonuses"/>
                                </a>
                            </AccordionItemPanel>
                        </AccordionItem>
                    </Accordion>
                    </div>  
                    <div className="games-list block">
                        <h3>{t('D\'autres jeux')}</h3>
                        <GamesList hide="ultimate-poker"/>
                    </div>
                </div>
            </div>
        </motion.div>
        </div>
        );
    }
}
const pageVariants = {
    initial: {
        opacity: 0,
        position : "relative"

    },
    in: {
        opacity: 1,
        position : "relative"

    },
    out: {
        opacity: 0,
        position : "absolute"
    }
};

const pageTransition = {
    type: "crossfade",
    ease: "anticipate",
    duration: 1
};

const pageStyle = {
    //position: "absolute",
    width : "100%"
};
export default withTranslation()(UltimatePoker);
