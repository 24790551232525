import React from 'react';
import Home from './Components/Home';
import EventsList from './Components/EventsList';
import Event from './Components/Event';
import Punto from './Components/Punto';
import UltimatePoker from './Components/UltimatePoker';
import PokerTwentyOne from './Components/PokerTwentyOne';
import Multicolore from './Components/Multicolore';
import BlackJack from './Components/BlackJack';
import TexasHoldEm from './Components/TexasHoldEm';
import UltimateHoldEm from './Components/UltimateHoldEm';
import Contact from './Components/Contact';
import { AnimatePresence } from "framer-motion";
import { Switch, Route, useLocation } from 'react-router-dom';
import  { events } from './Api/ApiEvents'
import i18n from "i18next";
import './i18n.js';
import './App.css';

function App() {
  const location = useLocation()

  const base = '/:locale(en|fr|zh)?';
  React.useEffect(() => {
      if (window.history.replaceState) {
          let paths = window.location.pathname.split('/');
          let language = i18n.language;
          paths.shift();

          if(paths[0].length == 2) {
              if (language === 'fr') {
                  // Remove language
                  paths.shift();
              }
              else {
                  paths[0] = language;
              }
          } else {
              if (language !== 'fr') {
                  // Add new language
                  paths.unshift(language);
              }
          }
      }
  }, [location]);

  return (
    <div className="App">
      <AnimatePresence>
      <Switch location={location} key={location.pathname}>
        <Route exact path='/' component={Home} />
        <Route path={`${base}/game/punto-banco`} component={Punto} />
        <Route path={`${base}/game/ultimate-poker`} component={UltimatePoker} />
        <Route path={`${base}/game/poker-21`} component={PokerTwentyOne} />
        <Route path={`${base}/game/multicolore`} component={Multicolore} />
        <Route path={`${base}/game/texas-hold-em`} component={TexasHoldEm} />
        <Route path={`${base}/game/ultimate-hold-em`} component={UltimateHoldEm} />
        <Route path={`${base}/game/black-jack`} component={BlackJack} />

        <Route path='/coordinates' component={Contact} />
        {/*<Route path='/events' component={EventsList} />
        {
          events.map((ev, i)=> (
            <Route
              key = {i}
              path = {'/event/'+ev.slug}
              render = {
                () => (
                  <Event event={ev}/>
                )
              }
            />
          ))
        }*/}
        <Route component={Home} />
      </Switch>
      </AnimatePresence>
    </div>
  );
}

export default App;
