import React, { Component }  from 'react'
import Header from './Header'
import GamesList from './GamesList'
import { Link } from 'react-router-dom';
import { motion } from "framer-motion";
import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
} from 'react-accessible-accordion';
import i18n from "i18next";
import * as constants from '../Constants/Constants'
import { withTranslation } from 'react-i18next';
import '../css/game.scss'
import {Helmet} from "react-helmet";


class PokerTwentyOne extends Component {

    componentDidMount() {
        setTimeout(function(){
            window.scrollTo({
                top: 0,
                left: 0,
                behavior: 'smooth'
            });
        }, 100);
        if(document.getElementById("punto-footer") !== null) {
            let pfooter = document.getElementById("punto-footer")
            pfooter.style.display = "block";
        }
        document.body.classList.remove("no-footer");
    }

    render() {
        let { t } = this.props;
        return(
            <div>
                <Helmet htmlAttributes={{ lang : i18n.language }}>
                    <title>{t('Découvrez le Poker 21- Punto Club Paris')}</title>
                    <meta name="description" content={t('Familiarisez-vous avec les règles du Poker 21. 2 tables de jeux y sont dédiées au Punto Club.')} />
                </Helmet>
                <motion.div
                    style={pageStyle}
                    initial="initial"
                    animate="in"
                    exit="out"
                    variants={pageVariants}
                    transition={pageTransition}
                >
                        <div id="poker-21">
                            <Header backButton="true"/>
                            <div className="contact">
                                <Link to={{ pathname: '/coordinates',  state: { from: this.props.location.pathname } }} >
                                    <img src={`${constants.PATH_TO_IMAGES+"gold-geoloc.png"}`} alt="Icon" />
                                    <span>{t('S\'y rendre')}</span><span>/</span><span>{t('Contact')}</span>
                                </Link>
                            </div>
                            <div className="top">
                                <h1>{t('Le Poker 21')}</h1>
                                <h3>{t('Le Poker 21, c\'est quoi ?')}</h3>
                                <p>{t('Savoureux mélange de Black Jack et de Poker, le Poker 21 est aussi baptisé le "BLACK JACK PARISIAN STYLE"')}</p>
                            </div>
                            <div className="content">
                                <div className="block icons">
                                    <div id="tables">
                                        <img src={`${constants.PATH_TO_IMAGES+"icon-tables.svg"}`} alt="Icon tables" />
                                        <span>{t('2 tables de jeu')}</span>
                                    </div>
                                    <div id="open">
                                        <img src={`${constants.PATH_TO_IMAGES+"icon-hours.svg"}`} alt="Icon hours" />
                                        <span>{t('De 21h à 5h 7j/7')}</span>
                                    </div>
                                </div>
                                <img className="illustration-game" src={`${constants.PATH_TO_IMAGES+"game-poker-21.jpg"}`} alt="Poker 21 illustration" />
                                <h3>{t('Les règles')}</h3>
                                <div className="accordion">
                                    <Accordion allowMultipleExpanded preExpanded={['a']}>
                                        <AccordionItem uuid="a">
                                            <AccordionItemHeading>
                                                <AccordionItemButton>
                                                    <h3>{t('Objectif')}</h3>
                                                </AccordionItemButton>
                                            </AccordionItemHeading>
                                            <AccordionItemPanel>
                                                <p>
                                                    {t('Le Poker 21 est une variante du Black Jack avec une touche de Poker. Le Poker 21 peut rassembler jusqu’à 7 joueurs à table et se joue avec 6 jeux de 52 cartes. Le joueur doit parvenir à obtenir un total de 21 points, ou s’y rapprocher au maximum et sans le dépasser. Un joueur assis peut miser sur plusieurs cases s’il le veut si des places sont libres à la table.')}
                                                </p>
                                            </AccordionItemPanel>
                                        </AccordionItem>
                                        <AccordionItem  uuid="b">
                                            <AccordionItemHeading>
                                                <AccordionItemButton>
                                                    <h3>{t('Valeurs des cartes')}</h3>
                                                </AccordionItemButton>
                                            </AccordionItemHeading>
                                            <AccordionItemPanel>
                                                <p>{t('Au poker 21, les cartes valent toute leur valeur nominale à l\'exception des figures qui valent 10 points. L\'as vaut 1 ou 11 au choix.')}</p>
                                            </AccordionItemPanel>
                                        </AccordionItem>
                                        <AccordionItem  uuid="c">
                                            <AccordionItemHeading>
                                                <AccordionItemButton>
                                                    <h3>{t('Déroulement du jeu')}</h3>
                                                </AccordionItemButton>
                                            </AccordionItemHeading>
                                            <AccordionItemPanel>
                                                <p className="title"><span className="diamond">&#x2B27;</span>{t('Placement des mises')}</p>
                                                <p>{t('Pour jouer, chaque joueur mise sur la case POK, puis mise sur la case 21. Le maximum des mises POK est fixé à l\'ouverture de chaque table par le directeur responsable dans la limite de 10 fois le minimum des mises.')}
                                                </p>
                                                <a className="photo" href={`${constants.PATH_TO_IMAGES+"mise-21.png"}`}  >
                                                    <p className="mobile-only">{t('Cliquez pour agrandir')}</p>
                                                    <img className="mid-size" src={`${constants.PATH_TO_IMAGES+"mise-21.png"}`} alt="Bets"/>
                                                </a>

                                                <p className="title"><span className="diamond">&#x2B27;</span>{t('Distribution des cartes')}</p>
                                                <p>{t('Le croupier distribue 2 cartes à chaque joueur et 1 pour lui même. Les cartes sont disposées face visible.')}</p>

                                                <p className="title"><span className="diamond">&#x2B27;</span>{t('Tour de jeu du joueur')}</p>
                                                <p>{t('Le joueur a 4 actions possibles durant son tour de jeu')} :</p>
                                                <p>{t('Passer')} : {t('Il estime que sa main est satisfaisante et reste avec ses 2 cartes.')}</p>
                                                <p>{t('Demander une carte')} : {t('Il peut demander autant de cartes qu’il le désire. S\'il vient à dépasser 21 alors il perd la mise.')}</p>
                                                <p>{t('Doubler sa mise')} : {t('Un joueur muni de ses deux premières cartes peut doubler sa mise « 21 ». Il ne pourra alors tirer qu’une seule carte supplémentaire.')}</p>
                                                <p>{t('La séparation des mains')} : {t('Un joueur peut dédoubler sa main si ses deux premières cartes sont de même valeur. Il doit alors engager une mise « 21 » égale à sa mise « 21 » initiale. Le joueur a alors deux mains et deux mises.')}</p>
                                                <p>{t('Le joueur reste, tire et joue dans des conditions de jeu simples en commençant par jouer sur la main la plus à sa droite avant de passer à la prochaine. Le nombre de séparations pour un même joueur est limité à trois.')}</p>
                                                <p>{t('Si le joueur sépare une paire d’As, il ne pourra tirer qu’une seule carte supplémentaire pour chaque As.')}</p>

                                                <p className="title"><span className="diamond">&#x2B27;</span>{t('Tour de jeu du croupier')}</p>
                                                <p>{t('Lorsque la distribution des cartes des joueurs est finie et tous les joueurs ont déterminé la situation de leurs mains, le croupier tire une ou plusieurs cartes pour lui-même.')}</p>
                                                <ul>
                                                    <li>{t('Si le croupier atteint 17 points ou plus, il ne peut pas tirer de cartes supplémentaires. ')}</li>
                                                    <li>{t('S’il a 16 points ou moins, il est tenu de tirer d’autres cartes jusqu’à ce que le total de ses points atteigne 17 ou plus.')}</li>
                                                </ul>
                                                <p className="title"><span className="diamond">&#x2B27;</span>{t('Mises perdantes et mises gagnantes du "21"')}</p>
                                                <p>{t('Le croupier, après avoir déterminé et annoncé les scores, procède au ramassage des mises « 21 » perdantes et au paiement des mises « 21 » gagnantes dans l’ordre prévu, de droite à gauche.')}
                                                    {t('Le croupier paie 1 pour 1 les joueurs qui ont au moins un point supérieur au sien. Le croupier paie 1 pour 1 les joueurs encore en jeu si sa main dépasse 21 points. En cas d’égalité les mises sont restituées.')}
                                                    {t('Le croupier paie 3 pour 2 si un joueur totalise 21 points avec ses deux premières cartes.')}
                                                    {t('Si la main du croupier totalise 21 points avec ses deux premières cartes, ce dernier ramasse toutes les mises sauf celles des joueurs qui totalisent 21 points avec leurs deux premières cartes.')}</p>

                                                <p className="title"><span className="diamond">&#x2B27;</span>{t('Mises perdantes et mises gagnantes du "POK"')}</p>
                                                <p>{t('Après le paiement des mises "21", le croupier complète son jeu, si nécessaire, jusqu’à 5 cartes. Il paie ensuite les joueurs conformément au barème du "Poker 21".')}</p>
                                                <a className="photo" href={`${constants.PATH_TO_IMAGES + i18n.language +"/poker21/bet-table.svg"}`}  >
                                                    <p className="mobile-only">{t('Cliquez pour agrandir')}</p>
                                                    <img src={`${constants.PATH_TO_IMAGES + i18n.language +"/poker21/bet-table.svg"}`} alt="Bet table"/>
                                                </a>
                                            </AccordionItemPanel>
                                        </AccordionItem>
                                    </Accordion>
                                </div>
                                <div className="games-list block">
                                    <h3>{t('D\'autres jeux')}</h3>
                                    <GamesList hide="poker-21"/>
                                </div>
                            </div>
                        </div>
                </motion.div>
            </div>
        );
    }
}
const pageVariants = {
    initial: {
        opacity: 0,
        position : "relative"

    },
    in: {
        opacity: 1,
        position : "relative"

    },
    out: {
        opacity: 0,
        position : "absolute"
    }
};

const pageTransition = {
    type: "crossfade",
    ease: "anticipate",
    duration: 1
};

const pageStyle = {
    //position: "absolute",
    width : "100%"
};
export default withTranslation()(PokerTwentyOne);
